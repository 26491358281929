<template>
  <div>
    <!-- ★ PDF Content Here ★ -->
    <div class="quoteSide" id="quote">
      <div id="qouteHeader">
        <!-- 견적서 제목 -->
        <div class="online quoteTitle">
          <h2 style="font-size: 28pt; font-weight: bold;">
          견 적 요 청 서
          </h2>
          <!-- <h3> (見 積 書)</h3> -->
        </div>
  
        <!-- 의뢰인 -->
        <div class="mt-3 mb-4">
          <div class="mr-4" style="width: 50%;">
            <b-table-simple 
              class="quoteTable"
              small 
              caption-top 
              stacked>
              <b-tbody class="client-info">
                <b-tr>
                  <b-th
                    rowspan="3"
                    class="text-center"
                    style="font-size: medium;"
                    >의 뢰 인
                  </b-th>
                  <b-td stacked-heading="요청일자: ">{{
                    quoteInfo.reqDate
                    }}
                  </b-td>
                  <b-td stacked-heading="회 사 명: ">{{
                    quoteInfo.companyName
                    }}
                  </b-td>
                  <b-td stacked-heading="담 당 자: ">{{
                    quoteInfo.name 
                    }}
                  </b-td>
                  <b-td stacked-heading="연 락 처: ">{{
                    quoteInfo.telNo
                    }}
                  </b-td>
                  <b-td stacked-heading="이 메 일: ">{{
                    quoteInfo.email
                    }}
                  </b-td>
                  <b-td stacked-heading="공 사 명: ">{{
                    quoteInfo.projectName
                    }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>

      <!-- 자재 내역 -->
      <div class="mt-5" id="itemTable">
        <label class="mt-1">■ 자재 발주 내역</label>
        <b-table
          head-variant="light"
          :items="quoteList"
          :fields="fields"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          responsive="sm"
          class="items-table"
        ></b-table>
      </div>
    </div>

    <!-- HowCost 인증 및 출처 -->
    <div id="quoteFooter" style="margin: 0 2rem 0.5rem 2rem; opacity: 60%;">
      <div style="width: 50%; float: left; margin-top: 1.75rem;">
        <span>{{ currentDateTime() }}</span>
        <span class="ml-3">http://xcost.me</span>
      </div>
      <div style="text-align: right; overflow: hidden; width: 50%;">
        <img :src="howcostLogo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quoteInfo: {
      type: Object,
      default: {},
    },
    quoteList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      // sortBy: "item",
      sortDesc: false,
      fields: [
        { key: "code", label: "코드", sortable: true, thClass: "w15" },
        { key: "item", label: "품명", sortable: true, thClass: "w25" },
        { key: "size", label: "규격", sortable: true, thClass: "w30" },
        { key: "unit", label: "단위", sortable: true, thClass: "w10" },
        { key: "qty", label: "수량", sortable: true, thClass: "w10" },
        // { key: "note", label: "비고", sortable: false, thClass: "w20" },
      ],
      items: [],
      baseURL: $baseURL,
      howcostLogo: "/images/HowCost-Company-logo.png",

      reqDate: "", //요청일자
      companyName: "", //회사이름
      name: "", //담당자 이름
      telNo: "", //담당자 연락처
      email: "", //담당자 이메일
      projectName: "", //공사명
      quoteCondition: "", //견적조건

      fileName: "",
      today: `${this.currentDateTime()}`,
    };
  },
  methods: {
    currentDateTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style>
  tbody > tr > td {
      padding: 0.6rem !important;
      font-size: 9.4pt;
  }
  .client-info > tr > td {
    padding: 0.3rem !important;
      font-size: 9.6pt;
  }
  .table thead > tr > th {
    padding: 0.5rem 0 0.5rem 1.25rem !important;
  }
</style>
