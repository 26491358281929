<template>
  <!-- @contextmenu.prevent  -->
  <div class="wrapper">
    <div class="animated fadeIn">
      <BRow>
        <BCol>
          <BCard header-tag="header" style="margin: 30px 30px 30px 30px;">
            <div slot="header">
              <BIcon width="1.25rem" height="1.25rem" icon="file-text-fill" />
              <strong style="font-size: larger;"> 견적 정보 </strong>
              <div class="card-header-actions">
                <small class="text-muted"
                  >공급자에게 발송하기 전 견적서를 검토 합니다</small
                >
                <!-- <b-button type="submit" variant="primary">정보수정</b-button>
                        <b-button class="ml-1" type="submit" variant="success">주소록</b-button> -->
              </div>
            </div>

            <BForm class="childContentCenter">
              <BRow>
                <BCol>
                  <div class="quotesolid">
                    <quoteContent 
                      slot="pdf-content"
                      id="pdf-content"
                      :quoteInfo="rData"
                      :quoteList="items"
                    />
                  </div>

                  <BRow>
                    <BCol class="text-right mt-4">
                      <b-button
                        id="pdfSave"
                        pill
                        class="mr-2"
                        size="lg"
                        variant="outline-danger"
                        @click="generatePDF()"
                        >
                        <BIcon width="1.25rem" height="1.25rem" icon="file-medical-fill" />
                        PDF저장
                        </b-button
                      >
                      <b-button
                        pill
                        size="lg"
                        variant="outline-success"
                        id="adrModalBtn"
                        @click="addressModal = true"
                        >
                        <BIcon width="1.25rem" height="1.25rem" icon="mailbox2" />
                        견적서 발송
                        </b-button
                      >
                    </BCol>
                  </BRow>
                </BCol>
              </BRow>
            </BForm>
          </BCard>
        </BCol>
      </BRow>
    </div>

    <!-- 주소록 Modal !! (메일발송 전) -->
    <b-modal
      v-model="addressModal"
      size="lg"
      title="메일발송"
      ok-title="견적서 최종 발송"
      ok-variant="danger"
      @ok="resultSend"
      ok-only
    >
      <b-alert
        :show="dismissCountDown"
        dismissible
        fade
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >
        주소록이 정상적으로 저장되었습니다
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false"
      >
        주소록을 선택하신 후 다시 시도해주세요
      </b-alert>

      <div>
        <b-row class="my-1">
          <b-col sm="2">
            <b-button variant="success" @click="addressAddFormCheck">
              주소록 추가
            </b-button>
          </b-col>
          <b-col sm="10" style="text-align: right; margin: auto;">
            <label style="margin: 0; color: crimson;">&lt;보내시는 이메일이 유효하지 않으면 발송이 제한됩니다&gt;</label>
          </b-col> 
        </b-row>

        <Transition name="slide-fade">
          <div
            v-show="addressAddForm"
            class="profile-solid mt-2"
            style="padding: 1rem 1rem 1rem 1rem;"
          >
            <b-form @submit="addressBookSave">
              <img
                class="profile-icon mr-3"
                :src="profileLogo"
                style="float: left;"
              />

              <b-row class="my-1">
                <b-col sm="2">
                  <label for="input-small">이 메 일:</label>
                </b-col>
                <b-col sm="10">
                  <b-form-input
                    id="input-small"
                    size="sm"
                    type="email"
                    v-model="receiverEmail"
                    :state="validEmailState"
                    placeholder="이메일을 입력해주세요 (필수)"
                    required
                  >
                  </b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="2">
                  <label for="input-small">이름(별칭):</label>
                </b-col>
                <b-col sm="10">
                  <b-form-input
                    id="input-small"
                    size="sm"
                    v-model="receiverName"
                    placeholder="해당 메일을 알아볼 수 있는 이름을 입력해주세요 (선택)"
                  >
                  </b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="2">
                  <label for="input-small">연 락 처:</label>
                </b-col>
                <b-col sm="10">
                  <b-form-input
                    size="sm"
                    id="input-small"
                    type="number"
                    v-model="receiverPhoneNum"
                    placeholder="연락처를 입력하세요 (선택)"
                  >
                  </b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="2">
                  <label for="input-small">그 룹:</label>
                </b-col>

                <b-col sm="7" v-if="!isCreateGroup">
                  <b-form-select 
                    id="input-small"
                    size="sm" 
                    v-model="addGroup" 
                    :options="groupOptions"
                  >
                  </b-form-select>
                </b-col>
                <b-col sm="7" v-else>
                  <b-form-input
                    id="input-small"
                    size="sm"
                    v-model="addGroup"
                    v-show="isCreateGroup"
                    placeholder="생성할 그룹명을 입력하세요"
                  >
                  </b-form-input>
                </b-col>

                <b-col sm="3" style="margin: auto;">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="createGroup"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >새그룹 생성
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <div class="mt-3 mr-3" style="text-align: end;">
                <b-button
                  block
                  variant="warning"
                  style="color: white;"
                  type="submit"
                  >주소 저장</b-button
                >
              </div>
            </b-form>
          </div>
        </Transition>

        <!-- 주소록 선택 -->
        <div class="mt-4">
          <!-- <label>■ 주소록 선택</label> -->
          <div class="address-solid pt-1">
            <div class="padding-075" style="border-bottom: 1px solid rgb(234, 234, 234);">
              <b-dropdown text="그룹필터 " class="w20" variant="outline-dark">
                <div v-for="(item, index) in groupAllOptions" :key="index">
                  <b-dropdown-item 
                    :value="item.value"
                    @click="selectAdrGroup(item.value)"
                  >
                    {{ item.text }}
                  </b-dropdown-item>
                </div>

              </b-dropdown>
              <label class="ml-3">조회그룹: <b>{{ groupText() }}</b></label>
            </div>

            <div v-if="addressList.length > 0" class="padding-025">
              <div v-for="(item, index) in addressList" :key="index" style="">
                <ul
                  class="menu align-center expanded SMN_effect-59 mb-1 noneMaker"
                  style="cursor: pointer;"
                  :class="{ checked: item.isActive }"
                  @click="getData"
                >
                  <li
                    :data-index="index"
                    :data-email="item.receiverEmail"
                    :data-name="item.receiverName"
                    :data-seq="item.seq"
                  >
                    <a>
                      <img
                        v-if="item.isActive"
                        class="profile-icon-sm"
                        :src="checkBox"
                        style="text-align: left; margin: 0.5rem 2rem 0.5rem 1.25rem;"
                      />
                      <img
                        v-else
                        class="profile-icon-sm"
                        :src="profileLogo"
                        style="text-align: left; margin: 0.5rem 2rem 0.5rem 1.25rem;"
                      />

                      <span v-if="item.receiverName != ''">{{
                        item.receiverName
                      }}</span>
                      <span v-else>&lt;저장된 이름 없음&gt;</span>

                      <span class="ml-5" style="color: rgb(181, 181, 181);">{{
                        item.receiverEmail
                      }}</span>

                      <span
                        v-if="item.receiverPhoneNum != ''"
                        class="ml-5"
                        style="color: rgb(181, 181, 181);"
                        >{{ item.receiverPhoneNum }}</span
                      >
                      <span
                        v-else
                        class="ml-5"
                        style="color: rgb(181, 181, 181);"
                        >&lt;저장된 번호 없음&gt;</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-3">
                <h6 class="text-center"></h6>
                <b-pagination 
                  v-model="currentPage" 
                  :per-page="perPage"
                  :total-rows="rows" 
                  align="center">
                </b-pagination>
              </div>
            </div>
            <div
              v-else
              class="mt-2 mb-2"
              style="color: rgb(134, 134, 134); text-align: center;"
            >
              <h6 class="mb-0">저장된 주소록이 없습니다</h6>
              <h6 class="mb-0">
                '<b>주소록 추가</b>' 버튼을 클릭하여 주소를 추가해주세요
              </h6>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <Spinner 
      :showSpinner="loading" 
      :showMessage="loadingMessage"
      :infoMessage="infoMessage">
    </Spinner>
  </div>
</template>



<script>
import VueHtml2pdf from "vue-html2pdf";
import html2pdf from "html2pdf.js";
import quoteContent from "./QuoteContent";

import {
  apiCall,
  apiMultiPart,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  alertConfirm,
  alertPageMove,
  warningAlertPageMove,
  getMask,
  warpSite,
} from "../../../common/utils";

export default {
  name: 'QuoteInfo',
  props: {
    data: Object,
  },
  data() {
    return {
      items: [],
      rData: {},
      baseURL: $baseURL,
      profileLogo: "/images/profile-icon.png",
      checkBox: "/images/check-icon.png",

      reqDate: "", //요청일자
      companyName: "", //회사이름
      name: "", //담당자 이름
      telNo: "", //담당자 연락처
      email: "", //담당자 이메일
      projectName: "", //공사명
      quoteCondition: "", //견적조건

      fileName: "",

      // modal 속성
      addressModal: false,

      addressAddForm: false,
      emailRegexp: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,

      // 주소록 field
      userNo: "",
      userId: "",
      receiverEmail: "",
      receiverName: "",
      receiverPhoneNum: "",
      memo: "",
      addGroup: "", //그룹 추가
      findGroup: "ALL", //찾을 그룹 값 (선택 그룹) [default: 'ALL']
      createGroup: "not_accepted", //그룹 체크박스
      isCreateGroup: false, //그룹 생성or선택 상태
      groupOptions: [], // 전체보기 그룹이 제외된 [실 그룹]
      groupAllOptions: [], // 전체 그룹: { value: 'ALL', text: '< 전체보기 >' } 형식으로 추가

      addressList: [],
      selectList: [],

      hardGroupOptions: [
        {
          text: '<전체보기>',
          value: 'ALL',
        },
        {
          text: '<그룹 미지정>',
          value: '',
        }
      ], //필수 그룹메뉴

      pdfBlob: null,

      currentPage: 1, // defualt select page
      rows: 100, // 전체 row 개수
      perPage: 10, // 페이지당 개수

      // Alert bar
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      loading: false,
      loadingMessage: "견적서 발송중",
      infoMessage: "(건당 최대 20초 정도 소요됩니다)",

      today: `${this.currentDateTime()}`,
      pdfOption: {
        margin: 0,
        filename: this.fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        // pagebreak: { mode: 'avoid-all' } // 페이지 분할 방식 설정
      },

    };
  },
  async created() {
    console.log("-------- created --------" );
    try{
      this.userNo = this.data.userNo;
      this.userId = this.data.userId;
      this.quoteCondition = this.data.quoteCondition;
  
      await this.itemsLoad();
      this.fileName = `${this.companyName}_견적요청서_${this.today}`;
      this.pdfOption.filename = this.fileName;
  
      await this.adrBookAllSearch();
      await this.groupAllFind();
    }catch(err){
      console.log('ERROR: ' + err);
      await warningAlertPageMove( this.$bvModal, '비정상적인 접근입니다. 페이지 종료 후 다시 시도해주세요.', '오류' );
      return;
    }
  },

  watch: {
    currentPage: 'selectAdrGroupPaging',
    addressList: 'selectActive',
    createGroup: 'groupCreate',
  },

  components: {
    VueHtml2pdf,
    quoteContent,
  },
  
  methods: {
    currentDateTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    // Alert
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showMiniAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    // 견적 자재 목록
    async itemsLoad() {
      this.reqDate = this.data.reqDate;
      this.companyName = this.data.companyName;
      this.name = this.data.name;
      this.telNo = this.data.telNo;
      this.email = this.data.email;
      this.projectName = this.data.projectName;

      this.rData = {
        reqDate: this.data.reqDate,
        companyName: this.data.companyName,
        name: this.data.name,
        telNo: this.data.telNo,
        email: this.data.email,
        projectName: this.data.projectName,
      }

      for (const item of this.data.list) {
        let itemsJson = {};

        // 규격(STAD)에 품명(NAME)이 들어가있으면 지우기
        if (item.STAD.includes(`${item.NAME}, `)) {
          item.STAD = item.STAD.replace(`${item.NAME}, `, '');
        } else if (item.STAD.includes(`, ${item.NAME}`)) {
          item.STAD = item.STAD.replace(`, ${item.NAME}`, '');
        } else if (item.STAD.includes(item.NAME)) {
          item.STAD = item.STAD.replace(item.NAME, '');
        }

        itemsJson.code = item.CODE;
        itemsJson.item = item.NAME;
        itemsJson.size = item.STAD;
        itemsJson.unit = item.UNIT;
        itemsJson.qty = Number(item.QUAN).toLocaleString();
        itemsJson.note = item.REMARK;

        this.items.push(itemsJson);
      }
    },

    async resultSend() {
      if (this.selectList.length <= 0) {
        await alertWarn(this.$bvModal, "주소록을 선택하신 후 다시 시도해주세요", "대상없음");
        this.showDismissibleAlert = true;
        this.addressModal = true;
        return;
      }
      await this.quoteSend();
    },

    // 견적서 메일 발송
    async quoteSend() {
      console.log("메일발송");
      
      this.loading = true;

      try {
        await this.pdfSave();
  
        const params = {
          userNo: this.userNo,
          userId: this.userId,
          reqDate: this.reqDate,
          myCompanyName: this.companyName,
          myName: this.name,
          myEmail: this.email,
          projectName: this.projectName,
          quoteCondition: this.quoteCondition,
          fileName: this.fileName,
          targetList: this.selectList,
        };
  
        const r = await apiCall("post", `/api/quote/send-mail`, params);
        if (r.code === 200) {
          console.log(" Mail Send Success ! ");
          const {body} = r.result;
          await alertPageMove(this.$bvModal, `요청하신 ${body.successCnt}건의 메일이 성공적으로 발송되었습니다.`, "메일발송 성공");
        } else if (r.code === 400) {
          console.log( "Mail sent failed" );
          await warningAlertPageMove( this.$bvModal, '비정상적인 접근입니다. 페이지 종료 후 다시 시도해주세요.', '오류' );
        } else {
          console.log( "Mail sent failed" );
          await warningAlertPageMove( this.$bvModal, r.message, '재시도 권고', warpSite() );
        }
      } catch(err) {
        console.log( err );
        this.loading = false;
        await alertWarn( this.$bvModal, 'ERROR: ' + err.message, 'ERROR' );
      }
    },


    /*****************************
     *  ▼  PDF 관련 Methods  ▼  
     *****************************/

    // PDF 파일 사용자 저장
    async generatePDF() {
      console.log("PDF 저장");
      const element = document.getElementById('pdf-content');
      await html2pdf().set(this.pdfOption).from(element).save();
    },

    // PDF 파일 서버로 전송 (메일첨부)
    async pdfSave() {
      const element = document.getElementById('pdf-content');
      const pdfBlob = await html2pdf().from(element).outputPdf('blob', this.pdfOption);
      const pdfFile = new Blob([pdfBlob], { type: 'application/pdf' });

      let formData = new FormData();
      formData.append('pdfFile', pdfFile, `${this.fileName}.pdf`);
      try {
        const r = await apiMultiPart(`/api/quote/pdf-upload`, formData);
        if (r.code===200){
          console.log("견적서 파일 저장 완료");
        } else {
          console.log("견적서 파일 저장 실패");
          await warningAlertPageMove( this.$bvModal, '견적서가 저장되지 않았습니다. 처음부터 재시도 해주세요.', '[오류] 재시도', warpSite() );
        }
      } catch (error) {
        console.log(error);
      }
    },


    /*****************************
     *  ▼ AddressBook Methods ▼  *
     *****************************/

    async addressAddFormCheck() {
      if (this.addressAddForm) return (this.addressAddForm = false);
      if (!this.addressAddForm) return (this.addressAddForm = true);
    },

    // 선택된 element 'data-' 속성 값 추출
    async getData(event) {
      const target = event.target;
      const parent = target.closest("[data-email]");
      if (parent) {
        const index = parent.getAttribute("data-index");
        const seq = parent.getAttribute("data-seq");
        const email = parent.getAttribute("data-email");
        const name = parent.getAttribute("data-name");

        const select = {
          index: parseInt(index),
          seq: parseInt(seq),
          email: email,
          name: name,
        };

        this.activeToggle(select.index);

        if (this.addressList[index].isActive) {
          this.selectList.push(select);
        } else {
          this.selectList = this.selectList.filter(
            (item) => item.index !== select.index
          );
        }
        // console.log("현재 선택한 수신자 주소들", this.selectList);
      }
    },

    // 주소록 선택된 이메일 style 변경 토글
    async activeToggle(index) {
      this.addressList[index].isActive = !this.addressList[index].isActive;
    },

    // 기존에 사용자가 선택한 연락처에 Style을 유지
    async selectActive() {
      for (const item of this.addressList) {
        const selectItem = this.selectList.find(sItem => sItem.seq === item.seq);
        if(selectItem) {
          item.isActive = true;
        }
      }
    },

    // 주소록 저장
    async addressBookSave(e) {
      e.preventDefault();
      try {
        const param = {
          userNo: this.data.userNo,
          userId: this.data.userId,
          receiverEmail: this.receiverEmail,
          receiverName: this.receiverName,
          receiverPhoneNum: getMask(this.receiverPhoneNum),
          group: this.addGroup,
        };

        const r = await apiCall("post", `/api/address/book-save`, param);

        if (r.code === 200) {
          await this.adrBookAllSearch();
          await this.groupAllFind();

          // 입력폼 초기화
          this.receiverEmail = '';
          this.receiverName = '';
          this.receiverPhoneNum = '';
          this.addGroup = '';

          this.showMiniAlert();
        } else if (r.code === 400) {
            await warningAlertPageMove( this.$bvModal, '비정상적인 접근입니다. 페이지 종료 후 다시 시도해주세요.', '오류' );
        } else {
            await warningAlertPageMove( this.$bvModal, r.message, '재시도 권고', warpSite() );
        }
      }catch(err){
        console.log( err );
        await alertWarn( this.$bvModal, 'ERROR: ' + err.message, 'ERROR' );
      }
    },

    // 전체 페이징 조회
    async adrBookAllSearch() {
      const params = {
        userId: this.data.userId,
        userNo: this.data.userNo,
        currentPage: this.currentPage,
        perPage: this.perPage,
      }

      try {
        const r = await apiCall('post', `/api/address/book-all-page`, params);
        if (r.code===200){
          this.rows = r.result.adrBookList.rows;
          this.addressList = r.result.adrBookList.items;
          console.log("주소록 페이지 조회 완료");
        } else {
          console.log("주소록 페이지 조회 실패");
        }
      } catch (error) {
        console.log(error);
      }
    },
    
    // 페이징+그룹 조회
    async adrBookSearch() {
      const params = {
        userId: this.data.userId,
        userNo: this.data.userNo,
        group: this.findGroup,
        currentPage: this.currentPage,
        perPage: this.perPage,
      }

      try {
        const r = await apiCall('post', `/api/address/book-group-page`, params);
        if (r.code===200){
          this.rows = r.result.adrBookList.rows;
          this.addressList = r.result.adrBookList.items;
          console.log("주소록 그룹 조회 완료");
        } else {
          console.log("주소록 그룹 조회 실패");
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 새그룹 생성 toggle
    async groupCreate() {
      this.isCreateGroup = !this.isCreateGroup;
      if( this.isCreateGroup ) {
        this.createGroup = 'accepted';
      } else {
        this.createGroup = 'not_accepted';
      }
    },

    // 사용자 그룹 filter
    async groupAllFind() {
      const params = {
        userId: this.data.userId,
        userNo: this.data.userNo,
      }

      try {
        const r = await apiCall('post', `/api/address/book-find`, params);

        if (r.code===200){
          let groupSet = new Set();

          for (let item of r.result.adrBookList) {
            groupSet.add(item.group);
          }

          // 기존 그룹 목록 초기화
          this.groupAllOptions = [];
          groupSet.delete('');
          let data;

          // 하드코딩 필수 그룹메뉴
          for (let item of this.hardGroupOptions) {
            data = {
              text: item.text,
              value: item.value,
            }
            this.groupAllOptions.push(data);
          }

          // 사용자가 지정한 그룹
          for (let item of groupSet) {
            data = {
              text: item,
              value: item,
            }
            this.groupAllOptions.push(data);
          }

          this.groupOptions = this.groupAllOptions.filter(item => item.value !== this.hardGroupOptions[0].value);
          console.log(" this.groupOptions ", this.groupOptions);

          console.log("사용자 그룹 조회 완료");
        } else {
          console.log("사용자 그룹 조회 실패");
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 그룹필터 조회 (그룹필터 선택 시)
    async selectAdrGroup(val) {
      this.findGroup = val;
      this.currentPage = 1;
      if (val === 'ALL') {
        this.adrBookAllSearch();
      } else {
        this.adrBookSearch();
      }
    },

    // 페이징 분기처리 (그룹필터 선택 후 페이지 이동 시)
    async selectAdrGroupPaging() {
      const val = this.findGroup;
      if (val === 'ALL') {
        this.adrBookAllSearch();
      } else {
        this.adrBookSearch();
      }
    },

    // 그룹명 이름 표시
    groupText() {
      const text = this.findGroup;
      switch (text) {
        case this.hardGroupOptions[0].value: {
          return this.hardGroupOptions[0].text;
          break;
        }
        case this.hardGroupOptions[1].value: {
          return this.hardGroupOptions[1].text;
          break;
        }
        default: {
          return text; 
          break;
        }
      }
    }

  },
  computed: {
    validEmailState() {
      if (!this.emailRegexp.test(this.receiverEmail)) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "/src/assets/scss/hoverStyle";
</style>
